import { useState, useEffect } from "react";
import React from "react";
import { useSelector } from "react-redux";
import "../../components/Employee/Employee.css";
import AdminApi from "../../api/AdminApi";
import { Button } from "../Button/Button";
import "../Button/Button.css";
import AddressAutocomplete from "../AddressAutocomplete/AddressAutocomplete";

const AddSitePopup = ({ onCancelClick, onDataSubmit }) => {
  useEffect(() => {
    window.addEventListener("keydown", handleKeyPress);

    return () => {
      window.removeEventListener("keydown", handleKeyPress);
    };
  }, []);

  const handleKeyPress = (event) => {
    if (event.keyCode === 27) {
      onCancelClick();
    }
  };
  const [siteName, setSiteName] = useState("");
  const [siteAddress, setSiteAddress] = useState("");
  const [latitude, setLatitude] = useState(0.0);
  const [longitude, setLongitude] = useState(0.0);
  const [siteManagerName, setSiteManagerName] = useState("");
  const [siteManagerEmail, setSiteManagerEmail] = useState("");
  const [siteManagerPhone, setSiteManagerPhone] = useState("");

  const { adminId } = useSelector((state) => state.auth);

  const [clients, setClients] = useState([]);
  const [selectedClient, setSelectedClient] = useState({});

  const handlePlaceSelect = (location) => {
    setSiteAddress(location.address);
    setLatitude(location.latitude);
    setLongitude(location.longitude);
  };

  useEffect(() => {
    fetchClients();
  }, []);

  const fetchClients = async () => {
    const formData = new FormData();
    formData.append("adminId", adminId);
    try {
      const response = await AdminApi.fetchAllClients(formData);
      if (response.status) {
        setClients(response.data.response.clients);
      }
    } catch (error) {
      localStorage.clear();
      window.location.href = "/login";
    }
  };

  const submitFormData = (e) => {
    e.preventDefault();

    if (
      !siteName ||
      !siteAddress ||
      !siteManagerName ||
      !siteManagerEmail ||
      !siteManagerPhone
    ) {
      alert("Please add missing fields");
      return;
    }

    createSite();
  };

  const createSite = async () => {
    const formData = new FormData();
    formData.append("adminId", adminId);
    formData.append("siteName", siteName);
    formData.append("siteAddress", siteAddress);
    formData.append("latitude", latitude);
    formData.append("longitude", longitude);
    formData.append("clientId", selectedClient.id);
    formData.append("siteManagerName", siteManagerName);
    formData.append("siteManagerEmail", siteManagerEmail);
    formData.append("siteManagerPhone", siteManagerPhone);

    try {
      const response = await AdminApi.createSite(formData);
      if (response.status === 200) {
        onDataSubmit();
      } else {
        localStorage.clear();
        window.location.href = "/login";
      }
    } catch (error) {
      console.error(error);
    }
  };

  const onClientOptionChangeHandler = (event) => {
    const client = clients[event.target.value];
    setSelectedClient(client);
  };

  return (
    <div className="AddEmployeePopupViewContainer">
      <form className="AddEmployeePopupContainer" onSubmit={submitFormData}>
        <span className="AddEmployeePopupTitle">Create Site</span>
        <div className="InputContainer">
          <div
            style={{ marginTop: "24px" }}
            className="drop-down-with-text-container"
          >
            <span className="InputTitles">
              Choose a client <span style={{ color: "#eb2727" }}>*</span>
            </span>

            <select
              style={{ marginTop: "8px" }}
              className="select-all cstm-lastdays"
              onChange={onClientOptionChangeHandler}
            >
              <option>--</option>
              {clients.map((site, index) => {
                return (
                  <option key={index} value={index}>
                    {site.name}
                  </option>
                );
              })}
            </select>
          </div>

          <div style={{ marginTop: "20px" }}>
            <div style={{ display: "flex" }}>
              <span className="InputTitles">
                Site name <span style={{ color: "#eb2727" }}>*</span>
              </span>
              <span className="InputTitles" style={{ marginLeft: "16px" }}>
                Site manager name <span style={{ color: "#eb2727" }}>*</span>
              </span>
            </div>

            <div style={{ display: "flex", marginTop: "5px" }}>
              <input
                className="Input"
                type="text"
                value={siteName}
                onChange={(e) => setSiteName(e.target.value)}
              />
              <input
                className="Input"
                type="text"
                style={{ marginLeft: "16px" }}
                value={siteManagerName}
                onChange={(e) => setSiteManagerName(e.target.value)}
              />
            </div>
          </div>

          <div style={{ marginTop: "20px" }}>
            <div style={{ display: "flex" }}>
              <span className="InputTitles">
                Site manager email <span style={{ color: "#eb2727" }}>*</span>
              </span>
              <span className="InputTitles" style={{ marginLeft: "16px" }}>
                Site manager phone <span style={{ color: "#eb2727" }}>*</span>
              </span>
            </div>

            <div style={{ display: "flex", marginTop: "5px" }}>
              <input
                className="Input"
                type="text"
                value={siteManagerEmail}
                onChange={(e) => setSiteManagerEmail(e.target.value)}
              />
              <input
                className="Input"
                type="text"
                style={{ marginLeft: "16px" }}
                value={siteManagerPhone}
                onChange={(e) => setSiteManagerPhone(e.target.value)}
              />
            </div>
          </div>

          <div
            style={{
              display: "flex",
              alignSelf: "flex-end",
              marginTop: "32px",
              width: "100%",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
              }}
            >
              <span className="InputTitles-NoWidth">
                Site address <span style={{ color: "#eb2727" }}>*</span>
              </span>
              <AddressAutocomplete
                className="Input"
                type="text"
                style={{ width: "78%" }}
                selected={siteAddress}
                onChange={handlePlaceSelect}
              />
            </div>
          </div>
          <div
            style={{
              display: "flex",
              alignSelf: "flex-end",
              marginTop: "32px",
            }}
          >
            <Button
              onClick={onCancelClick}
              buttonStyle="btn--outline"
              styles={{
                marginLeft: "auto",
              }}
            >
              Cancel
            </Button>

            <Button
              type="submit"
              styles={{
                marginLeft: "12px",
              }}
            >
              Continue
            </Button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default AddSitePopup;
