import { useEffect, useState } from "react";
import Navbar1 from "../../components/Navbar/Navbar1";
import EmployeeDetailSidebar from "../../components/Sidebar/EmployeeDetailSidebar";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import AdminApi from "../../api/AdminApi";
import { Divider } from "../../components/Common/Divider";
import { ArrowBack } from "@mui/icons-material";
import { Avatar } from "@mui/material";

import Trial3 from "../../../src/img/trial_3.jpg";

const ClientDetails = () => {
  const [clientDetails, setClientDetails] = useState({});
  const navigate = useNavigate();
  const { id } = useParams();

  const { adminId } = useSelector((state) => state.auth);

  useEffect(() => {
    fetchClientDetails();
  }, []);
  async function fetchClientDetails() {
    const formData = new FormData();
    formData.append("adminId", adminId);
    formData.append("clientId", id);
    try {
      const response = await AdminApi.fetchClientDetails(formData);
      if (response.status) {
        setClientDetails(response.data.response);
      }
    } catch (error) {
      navigate("/clients");
    }
  }

  return (
    <>
      <Navbar1 />
      <div className="cstm-contant-main">
        <div className="cstm-contant-ed">
          <EmployeeDetailSidebar />
          <div className="cstm-contant-ed-right">
            <div className="cstm-prevnext">
              <div className="cstm-pn-title">Previous/Next Employee </div>
              <div className="pg-ar">
                <button className="pg-ar-left">
                  <span className="material-icons">chevron_left</span>
                </button>
                <button className="pg-ar-right">
                  <span className="material-icons">chevron_right</span>
                </button>
              </div>
            </div>

            <div className="cstm-pfo-main">
              <div className="cstm-pfo-main-left">Profile Overview</div>
              <div className="cstm-pfo-main-right">
                <div className="cstm-pfo-title">
                  Employee added on 31st July 2021
                </div>
                <div className="cstm-pfo-btn">
                  <button className="btn btn--outline btn--medium">
                    Mark Inactive
                  </button>
                </div>
              </div>
            </div>

            <Divider />

            <div className="cstm-user-name-photo">
              <div className="cstm-user-name-photo-img">
                <img src={Trial3} />
              </div>
              <div className="cstm-user-name-photo-title">
                {clientDetails.name}
              </div>
              <div className="cstm-user-name-photo-active">Active</div>
            </div>

            <div className="cstm-ed-info">
              <div className="cstm-ed-info-tp">
                <div className="cstm-ed-info-tp-box">
                  <div className="cstm-ed-info-tp-box-left">
                    <span className="cstm-ed-title">Mobile</span>:
                  </div>
                  <div className="cstm-ed-info-tp-box-right">{"Monke"}</div>
                </div>

                <div className="cstm-ed-info-tp-box">
                  <div className="cstm-ed-info-tp-box-left">
                    <span className="cstm-ed-title">Email</span>:
                  </div>
                  <div className="cstm-ed-info-tp-box-right">{"Monke"}</div>
                </div>

                <div className="cstm-ed-info-tp-box">
                  <div className="cstm-ed-info-tp-box-left">
                    <span className="cstm-ed-title">SL.No</span>:
                  </div>
                  <div className="cstm-ed-info-tp-box-right">-</div>
                </div>

                <div className="cstm-ed-info-tp-box">
                  <div className="cstm-ed-info-tp-box-left">
                    <span className="cstm-ed-title">Address</span>:
                  </div>
                  <div className="cstm-ed-info-tp-box-right">-</div>
                </div>
              </div>
              <div className="cstm-ed-info-bt">
                <div className="cstm-ed-info-bt-box">
                  <div className="cstm-ed-info-bt-box-name">Shifts covered</div>
                  <div className="cstm-ed-info-bt-box-number">102</div>
                </div>
                <div className="cstm-ed-info-bt-box">
                  <div className="cstm-ed-info-bt-box-name">
                    Upcoming shifts
                  </div>
                  <div className="cstm-ed-info-bt-box-number">7</div>
                </div>
                <div className="cstm-ed-info-bt-box">
                  <div className="cstm-ed-info-bt-box-name">
                    Incidents Reported
                  </div>
                  <div className="cstm-ed-info-bt-box-number">23</div>
                </div>
                <div className="cstm-ed-info-bt-box">
                  <div className="cstm-ed-info-bt-box-name">Leaves taken</div>
                  <div className="cstm-ed-info-bt-box-number">16/32</div>
                </div>
                <div className="cstm-ed-info-bt-box">
                  <div className="cstm-ed-info-bt-box-name">
                    Declined shifts
                  </div>
                  <div className="cstm-ed-info-bt-box-number">15</div>
                </div>
                <div className="cstm-ed-info-bt-box">
                  <div className="cstm-ed-info-bt-box-name">
                    Total duty hours
                  </div>
                  <div className="cstm-ed-info-bt-box-number">874</div>
                </div>
                <div className="cstm-ed-info-bt-box">
                  <div className="cstm-ed-info-bt-box-name">Total payout</div>
                  <div className="cstm-ed-info-bt-box-number">14,300 AUD</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ClientDetails;
