import "./TermsAndConditions.css";

const TermsAndConditions = () => {
  return (
    <div className="terms-conditions-container">
      <h1 className="title">Terms and Conditions of Use</h1>

      <h2 className="section-title">1.0 Agreement</h2>
      <p className="section-text">
        This agreement (Agreement) is an agreement between **insert name** of
        **insert address** (us) and you (you). This Agreement is to be read
        together with all our other terms and conditions including our Privacy
        Policy (Privacy Policy). By visiting and using this application /
        platform (platform) and the using the features of this platform, you
        accept the terms and conditions of this Agreement and the Privacy
        Policy. If you do not wish to accept the terms and conditions of this
        Agreement the Privacy Policy, do not visit or use this platform.
      </p>

      <h2 className="section-title">
        2.0 Accuracy, completeness and timeliness of information
      </h2>
      <p className="section-text">
        The information on our platform is not necessarily comprehensive or
        up-to-date. We cannot and do not verify the accuracy of the user
        generated content. We recommend that you independently verify all user
        generated content before relying on it in any way.
      </p>
      <p className="section-text">
        To the extent permitted by law, we make no warranty or representation
        regarding the accuracy, completeness or timeliness of information on
        this platform (including any user generated content). You agree to
        release us from all loss or damage you may incur from relying on any
        information on our platform. To the extent that we are liable under the
        law, you agree that any such liability will be limited to the cost of
        resupplying the service.
      </p>
      <p className="section-text">
        You agree to release us from all loss or damage incurred as a result of
        any interference with or damage to your computer systems in connection
        with the use of this platform or a linked platform. We recommend that
        you take your own precautions to ensure that your use of our platform
        does not result in any infection for viruses or anything else that may
        monitor or interfere with or damage your computer or network.
      </p>
      <p className="section-text">
        We may vary the terms and conditions of this Agreement at any time
        without notice to you and without your prior consent.
      </p>

      <h2 className="section-title">
        3.0 Termination of Your access to the platform
      </h2>
      <p className="section-text">
        We may, on notice to you, and at our discretion, suspend or terminate
        your access to all or part of the platform without cause. We may also
        remove any user generated content that we deem to be offensive,
        defamatory or not within the bounds of normal public decency.
      </p>
      <p className="section-text">
        You agree to release us from and against all claims arising from;
        <ul className="bullet-list">
          <li>
            Your suspension or termination of access to part or all of our
            platform;
          </li>
          <li>The removal of user generated content.</li>
        </ul>
      </p>
      <p className="section-text">
        For the avoidance of doubt, in the event of the happening of one of
        these events we will not be liable to issue a refund to you for any
        costs paid by you.
      </p>

      <h2 className="section-title">4.0 Linked sites</h2>
      <p className="section-text">
        Our platform may contain links to platforms operated by third parties.
        Those links are provided for convenience and may not remain current or
        be maintained. Unless expressly stated otherwise, we do not endorse and
        are not responsible for the content on those linked platforms and have
        no control over or rights in those linked platforms.
      </p>

      <h2 className="section-title">5.0 Intellectual property rights</h2>
      <p className="section-text">
        You warrant that you own all copyright in all user generated content
        that you post on the platform. By posting content on our platform, you
        grant us a worldwide, perpetual, fee free licence to use all copyright
        in the content for any purpose (and to assign or sublicence such
        rights). You agree to indemnify us and our agents, officers and
        employees from and against all claims for breach of copyright arising
        from any content that you post on our platform.
      </p>
      <p className="section-text">
        Unless otherwise indicated, we own or license from third parties all
        rights, title and interest (including copyright, designs, patents,
        trademarks and other intellectual property rights) in this platform and
        in all of the material (including all text, graphics, logos, audio and
        software) made available on this platform (Content).
      </p>
      <p className="section-text">
        Your use of this platform and use of and access to any Content does not
        grant or transfer any rights, title or interest to you in relation to
        this platform or the Content. However we do grant you a licence to
        access the platform and view the Content on the terms and conditions set
        out in this Agreement and, where applicable, as expressly authorised by
        us and/or our third party licensors for the sole purpose of finding
        information about us.
      </p>
      <p className="section-text">
        Except for the temporary copy held in the computers cache, and a single
        permanent copy for your personal reference, the material may not
        otherwise be used, copied, reproduced, published, stored in a retrieval
        system, altered or transmitted in any form or by any means in whole or
        part without our prior written approval. Any further reproduction or
        redistribution of this platform or the Content is prohibited and may
        result in civil and criminal penalties.
      </p>

      <h2 className="section-title">6.0 Unacceptable activity </h2>
      <p className="section-text">
        You must not do any act that we would deem to be inappropriate, is
        unlawful or is prohibited by any laws applicable to our platform,
        including but not limited to:
        <ul className="bullet-list">
          <li>
            any act that would constitute a breach of either the privacy
            (including uploading private or personal information without an
            individual's consent) or any other of the legal rights of
            individuals;
          </li>
          <li>
            using this platform to defame or libel us, our employees or other
            individuals;
          </li>
          <li>
            uploading files that contain viruses that may cause damage to our
            property or the property of other individuals;
          </li>
          <li>
            posting or transmitting to this platform any non-authorised material
            including, but not limited to, material that is, in our opinion,
            likely to cause annoyance, or which is defamatory, racist, obscene,
            threatening, pornographic or otherwise or which is detrimental to or
            in violation of our systems or a third party's systems or network
            security.
          </li>
        </ul>
      </p>
      <p className="section-text">
        If we allow you to post any information to our platform, we have the
        right to take down this information at our sole discretion and without
        notice. If a listing is terminated by us we will not issue a refund or
        be liable for any associated expenses or losses.
      </p>

      <h2 className="section-title">7.0 Warranties and disclaimers</h2>
      <p className="section-text">
        To the maximum extent permitted by law, including the Australian
        Consumer Law, we make no warranties or representations about this
        platform or the Content, including but not limited to warranties or
        representations that they will be complete, accurate or up-to-date, that
        access will be uninterrupted or error-free or free from viruses, or that
        this platform will be secure. To the extent that we are liable under the
        law, you agree that any such liability will be limited to the cost of
        resupplying the service.
      </p>

      <h2 className="section-title">8.0 Liability</h2>
      <p className="section-text">
        To the maximum extent permitted by law, including the Australian
        Consumer Law, in no event shall we be liable for any direct and indirect
        loss, damage or expense – irrespective of the manner in which it occurs.
        To the extent that we are liable under the law, you agree that any such
        liability will be limited to the cost of resupplying the service.
      </p>

      <h2 className="section-title">9.0 Jurisdiction and governing law </h2>
      <p className="section-text">
        Your use of the platform and the terms and conditions set out in this
        Agreement are governed by the law of the State of New South Wales and
        you submit to the exclusive jurisdiction of the courts exercising
        jurisdiction in State of New South Wales.
      </p>
    </div>
  );
};

export default TermsAndConditions;
