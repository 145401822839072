import React, { useRef, useEffect } from "react";

function AddressAutocomplete({ onChange }) {
  const inputRef = useRef(null);
  const autocomplete = useRef(null);

  useEffect(() => {
    const autocomplete = new window.google.maps.places.Autocomplete(
      document.getElementById("autocomplete")
    );
    autocomplete.addListener("place_changed", () => {
      const place = autocomplete.getPlace();
      const address = place.formatted_address;
      const location = {
        address: address,
        latitude: place.geometry.location.lat(),
        longitude: place.geometry.location.lng(),
      };
      onChange(location);
    });
  }, []);

  return (
    <input
      ref={inputRef}
      className="Input"
      type="text"
      id="autocomplete"
      style={{ width: "78%" }}
    />
  );
}

export default AddressAutocomplete;
