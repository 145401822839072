import "./ShiftDetails.css";

const ShiftFooter = ({ shiftDetails }) => {
  function convertUTCTo24HourString(utcTimestamp) {
    const date = new Date(utcTimestamp);
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const formattedTime = `${String(hours).padStart(2, "0")}${String(
      minutes
    ).padStart(2, "0")}`;
    return formattedTime;
  }

  function convertDurationToHoursMinutes(durationInMilliseconds) {
    const hours = Math.floor(durationInMilliseconds / 3600000); // 1 hour = 3600000 milliseconds
    const minutes = Math.floor((durationInMilliseconds % 3600000) / 60000); // 1 minute = 60000 milliseconds

    let result = "";

    if (hours > 0) {
      result += `${hours} ${hours === 1 ? "hour" : "hours"}`;
    }

    if (minutes > 0) {
      result += ` ${minutes} ${minutes === 1 ? "minute" : "minutes"}`;
    }

    return result.trim();
  }

  function convertUTCToDateString(utcTimestamp) {
    const date = new Date(utcTimestamp);

    const options = { month: "long", year: "numeric" };
    const dateFormatter = new Intl.DateTimeFormat("en-US", options);

    const dayOptions = { weekday: "long" };
    const dayDateFormatter = new Intl.DateTimeFormat("en-US", dayOptions);

    const formattedDate = dateFormatter.format(date);
    const formattedDay = dayDateFormatter.format(date);

    // Add the appropriate suffix to the day (e.g., "1st", "2nd", "3rd", "4th", etc.)
    const day = date.getDate();
    const daySuffix = getDaySuffix(day);
    const dateString = `${formattedDay}, ${day}${daySuffix} ${formattedDate}`;

    return dateString;
  }

  // Function to get the day suffix (e.g., "st", "nd", "rd", "th")
  function getDaySuffix(day) {
    if (day >= 11 && day <= 13) {
      return "th";
    }
    const lastDigit = day % 10;
    switch (lastDigit) {
      case 1:
        return "st";
      case 2:
        return "nd";
      case 3:
        return "rd";
      default:
        return "th";
    }
  }

  return (
    <div className="shift-footer-frame">
      <div className="div">
        <div className="div-wrapper">
          <div className="text-wrapper">Date</div>
        </div>
        <div className="div-wrapper-2">
          <div className="text-wrapper-2">Start time</div>
        </div>
        <div className="div-wrapper-3">
          <div className="text-wrapper-3">Allowed break time</div>
        </div>
        <div className="div-wrapper-4">
          <div className="text-wrapper-4">End Time</div>
        </div>
        <div className="div-wrapper-5">
          <div className="text-wrapper-5">Duration</div>
        </div>
      </div>
      <div className="div-2">
        <div className="div-wrapper-6">
          <div className="text-wrapper-6">
            {convertUTCToDateString(shiftDetails.startTime)}
          </div>
        </div>
        <div className="div-wrapper-7">
          <div className="text-wrapper-7">
            {convertUTCTo24HourString(shiftDetails.startTime)}
          </div>
        </div>
        <div className="div-wrapper-8">
          <div className="text-wrapper-8">
            {convertUTCTo24HourString(shiftDetails.endTime)}
          </div>
        </div>
        <div className="div-wrapper-9">
          <div className="text-wrapper-9">
            {convertDurationToHoursMinutes(shiftDetails.allowedBreakTime)}
          </div>
        </div>
        <div className="div-wrapper-10">
          <div className="text-wrapper-10">
            {convertDurationToHoursMinutes(shiftDetails.duration)}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ShiftFooter;
