import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import AdminApi from "../api/AdminApi";
import { Divider } from "../components/Common/Divider";
import CommonTable from "../components/Table/CommonTable";
import CustomPagination from "../components/Pagination/CustomPagination";
import ComplaintsSidebar from "../components/Sidebar/ComplaintsSidebar";
import ComplaintsHeader from "../components/Header/ComplaintsHeader";

const ComplaintsContainer = () => {
  const [incidents, setIncidents] = useState([]);
  const [tblIncidents, setTblIncidents] = useState([]);

  const { adminId } = useSelector((state) => state.auth);

  const navigate = useNavigate();

  useEffect(() => {
    fetchAllIncidents();
  }, []);

  useEffect(() => {
    const cl = incidents.map((incident, index) => ({
      index: index + 1,
      date: incident.dateTime,
      siteName: incident.siteName,
      incidentType: incident.incidentType,
    }));
    setTblIncidents(cl);
  }, [incidents]);

  const fetchAllIncidents = async () => {
    const formData = new FormData();
    formData.append("adminId", adminId);
    try {
      const response = await AdminApi.fetchAllIncidents(formData);
      if (response.status === 200) {
        setIncidents(response.data.response.incidentReports);
      } else {
        localStorage.clear();
        window.location.href = "/login";
      }
    } catch (error) {
      console.error(error);
    }
  };

  const onClickIncidentDetails = (e, rowData) => {
    const incidentId = incidents[rowData.index - 1].incidentId;
    navigate("/incident/" + incidentId);
  };

  const columns = [
    {
      title: "S.No",
      field: "index",
      emptyValue: () => <div>-</div>,
    },
    {
      title: "Date",
      field: "date",
      render: (rowData) => (
        <div>{new Date(rowData.date).toLocaleDateString()}</div>
      ),
      emptyValue: () => <div>-</div>,
    },
    {
      title: "Site Name",
      field: "siteName",
      emptyValue: () => <div>-</div>,
    },
    {
      title: "Incident Type",
      field: "incidentType",
      emptyValue: () => <div>-</div>,
    },
  ];

  return (
    <>
      <div className="cstm-contant-main">
        <div className="cstm-contant-main-cont">
          <ComplaintsSidebar />
          <div className="cstm-contant-main-cont-all">
            <div className="cstm-contant-main-cont-all-main">
              <div className="cstm-cont-header">
                <ComplaintsHeader child={"Incidents"} buttonchild={<></>} />
              </div>
              <Divider />
              <div className="cstm-contant-main-cont-table">
                <div>
                  <CommonTable
                    apiData={tblIncidents}
                    columns={columns}
                    options={{
                      thirdSortClick: false,
                      paginationPosition: "top",
                      search: false,
                      pageSize: 10,
                      pageSizeOptions: [10, 20, 30],
                      draggable: false,
                      search: false,
                      emptyRowsWhenPaging: false,
                    }}
                    onRowClick={(e, rowData) => {
                      onClickIncidentDetails(e, rowData);
                    }}
                    toolbarData={{
                      Pagination: (props) => {
                        let paginationprops = props;
                        paginationprops = {
                          ...paginationprops,
                          label: "Incidents showing : ",
                          positionPagination: "top",
                        };

                        return <CustomPagination {...paginationprops} />;
                      },
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ComplaintsContainer;
