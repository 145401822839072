// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bubble-container {
  width: 35.2vw;
  padding: 3vh 2vw;
  border-radius: 20px;
  background-color: #0e223f;
  display: flex;
  align-content: center;
}

.error-messag {
  color: red;
}

.image-style {
  width: 98px;
  height: 98px;
  margin: 0 18px 0 0;
  /* box-shadow: 0 0 6px 0 rgba(98, 208, 208, 0.2); */
}

.text-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.title-text {
  width: 100%;
  margin: 0px 224px 14.5px 18px;
  font-family: Helvetica;
  font-size: 22px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.27;
  letter-spacing: 0.22px;
  text-align: left;
  color: #f5f7fa;
}

.subtitle-text {
  margin: 0px 0 0px 18px;
  opacity: 0.8;
  font-family: Helvetica;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.38;
  letter-spacing: 0.32px;
  text-align: left;
  color: #9caec9;
}`, "",{"version":3,"sources":["webpack://./src/components/Home/Home.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,gBAAgB;EAChB,mBAAmB;EACnB,yBAAyB;EACzB,aAAa;EACb,qBAAqB;AACvB;;AAEA;EACE,UAAU;AACZ;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,kBAAkB;EAClB,mDAAmD;AACrD;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,uBAAuB;AACzB;;AAEA;EACE,WAAW;EACX,6BAA6B;EAC7B,sBAAsB;EACtB,eAAe;EACf,iBAAiB;EACjB,oBAAoB;EACpB,kBAAkB;EAClB,iBAAiB;EACjB,sBAAsB;EACtB,gBAAgB;EAChB,cAAc;AAChB;;AAEA;EACE,sBAAsB;EACtB,YAAY;EACZ,sBAAsB;EACtB,eAAe;EACf,mBAAmB;EACnB,oBAAoB;EACpB,kBAAkB;EAClB,iBAAiB;EACjB,sBAAsB;EACtB,gBAAgB;EAChB,cAAc;AAChB","sourcesContent":[".bubble-container {\n  width: 35.2vw;\n  padding: 3vh 2vw;\n  border-radius: 20px;\n  background-color: #0e223f;\n  display: flex;\n  align-content: center;\n}\n\n.error-messag {\n  color: red;\n}\n\n.image-style {\n  width: 98px;\n  height: 98px;\n  margin: 0 18px 0 0;\n  /* box-shadow: 0 0 6px 0 rgba(98, 208, 208, 0.2); */\n}\n\n.text-container {\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n}\n\n.title-text {\n  width: 100%;\n  margin: 0px 224px 14.5px 18px;\n  font-family: Helvetica;\n  font-size: 22px;\n  font-weight: bold;\n  font-stretch: normal;\n  font-style: normal;\n  line-height: 1.27;\n  letter-spacing: 0.22px;\n  text-align: left;\n  color: #f5f7fa;\n}\n\n.subtitle-text {\n  margin: 0px 0 0px 18px;\n  opacity: 0.8;\n  font-family: Helvetica;\n  font-size: 16px;\n  font-weight: normal;\n  font-stretch: normal;\n  font-style: normal;\n  line-height: 1.38;\n  letter-spacing: 0.32px;\n  text-align: left;\n  color: #9caec9;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
