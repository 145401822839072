import React, { useEffect, useState } from "react";
import "./App.css";
import EmployeeContainer from "./layouts/Employee/Employees";
import HomeContainer from "./layouts/Home";
import ShiftsContainer from "./layouts/shifts/Shifts";
import SubcontractorContainer from "./layouts/Subcontractor/Subcontractors";
import ClientsContainer from "./layouts/Client/Clients";
import SitesContainer from "./layouts/Sites";
import ComplaintsContainer from "./layouts/Complaints";
import AppContainer from "./layouts/App";
import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import AdminApi from "./api/AdminApi";
import Dashboard from "./layouts/Dashboard";
import FinancesContainer from "./layouts/Finance/Finances";
import EmployeeDetails from "./layouts/Employee/EmployeeDetails";
import ShiftDetails from "./layouts/shifts/ShiftDetails";
import ClientDetails from "./layouts/Client/ClientDetails";
import PastShift from "./layouts/shifts/PastShift";
import UpcomingShift from "./layouts/shifts/UpcomingShift";
import DeclinedShift from "./layouts/shifts/DeclinedShift";
import Login from "./layouts/user/Login";
import ResetPassword from "./layouts/user/ResetPassword";
import ForgotPassword from "./layouts/user/ForgotPassword";
import Navbar1 from "./components/Navbar/Navbar1";
import CeoDashboard from "./layouts/Management/CeoDashboard";
import { ThemeProvider } from "@mui/material";
import theme from "./style/Theme";
import CompanyDashboard from "./layouts/CompanyDashboard";
import Roster from "./layouts/Roster/Roster";
import ComplaintDetailsContainer from "./layouts/ComplaintDetails";
import PayslipDetails from "./layouts/Finance/PayslipDetails";
import InvoiceDetails from "./layouts/Finance/InvoiceDetails";
import PrivacyPolicy from "./layouts/PrivacyPolicy/PrivacyPolicy";
import TermsAndConditions from "./layouts/TermsAndConditions/TermsAndConditions";

function App() {
  const { isLoggedIn, StayLoggedIn, role } = useSelector((state) => state.auth);
  const location = useLocation();
  const [showNav, setNav] = useState(false);
  if (StayLoggedIn === false) {
    setTimeout(() => {
      AdminApi.logoutAdmin();
    }, 1000 * 60 * 60 * 24);
  }
  useEffect(() => {
    if (
      location.pathname === "/dashboard" ||
      location.pathname === "/roster" ||
      location.pathname === "/company-dashboard" ||
      location.pathname === "/employees" ||
      location.pathname === "/shifts" ||
      location.pathname === "/clients" ||
      location.pathname === "/sites" ||
      location.pathname === "/incidents" ||
      location.pathname === "/finances" ||
      location.pathname === "/past-shift" ||
      location.pathname === "/upcoming-shift" ||
      location.pathname === "/declined-shift" ||
      location.pathname === "/subcontractors" ||
      location.pathname === "/manage"
    ) {
      setNav(true);
    } else {
      setNav(false);
    }
  });
  return (
    <>
      {showNav && <Navbar1 />}
      <Routes>
        <Route exact path="/" element={<HomeContainer />} />
        <Route exact path="/login" element={<Login />} />
        <Route exact path="/downloads" element={<AppContainer />} />
        <Route
          exact
          path="/dashboard"
          element={
            <RestrictPage>
              {" "}
              <Dashboard />
            </RestrictPage>
          }
        />
        <Route
          exact
          path="/roster"
          element={
            <RestrictPage>
              {" "}
              <Roster />
            </RestrictPage>
          }
        />
        <Route
          exact
          path="/company-dashboard"
          element={
            <RestrictPage>
              {" "}
              <CompanyDashboard />
            </RestrictPage>
          }
        />
        <Route
          exact
          path="/employees"
          element={
            <RestrictPage>
              {" "}
              <EmployeeContainer />
            </RestrictPage>
          }
        />
        <Route
          exact
          path="/subcontractors"
          element={
            <RestrictPage>
              {" "}
              <SubcontractorContainer />
            </RestrictPage>
          }
        />
        <Route
          exact
          path="/employee-details/:id"
          element={
            <RestrictPage>
              <EmployeeDetails />{" "}
            </RestrictPage>
          }
        />
        <Route
          exact
          path="/clients"
          element={
            <RestrictPage>
              <ClientsContainer />
            </RestrictPage>
          }
        />
        <Route
          exact
          path="/client-details/:id"
          element={
            <RestrictPage>
              <ClientDetails />{" "}
            </RestrictPage>
          }
        />
        <Route
          exact
          path="/shifts"
          element={
            <RestrictPage>
              <ShiftsContainer />
            </RestrictPage>
          }
        />
        <Route
          exact
          path="/shift-details/:id"
          element={
            <RestrictPage>
              <ShiftDetails />{" "}
            </RestrictPage>
          }
        />
        <Route
          exact
          path="/sites"
          element={
            <RestrictPage>
              <SitesContainer />
            </RestrictPage>
          }
        />
        <Route
          exact
          path="/incidents"
          element={
            <RestrictPage>
              <ComplaintsContainer />
            </RestrictPage>
          }
        />
        <Route
          exact
          path="/incident/:id"
          element={
            <RestrictPage>
              <ComplaintDetailsContainer />{" "}
            </RestrictPage>
          }
        />
        <Route
          exact
          path="/finances"
          element={
            <RestrictPage>
              <FinancesContainer />
            </RestrictPage>
          }
        />
        <Route
          exact
          path="/payslip/:id"
          element={
            <RestrictPage>
              <PayslipDetails />{" "}
            </RestrictPage>
          }
        />
        <Route
          exact
          path="/invoice/:id"
          element={
            <RestrictPage>
              <InvoiceDetails />{" "}
            </RestrictPage>
          }
        />
        <Route
          exact
          path="/past-shift"
          element={
            <RestrictPage>
              <PastShift />
            </RestrictPage>
          }
        />
        <Route
          exact
          path="/upcoming-shift"
          element={
            <RestrictPage>
              <UpcomingShift />
            </RestrictPage>
          }
        />
        <Route
          exact
          path="/declined-shift"
          element={
            <RestrictPage>
              <DeclinedShift />
            </RestrictPage>
          }
        />
        <Route
          exact
          path="/manage"
          element={
            <RestrictPage>
              <CeoDashboard />
            </RestrictPage>
          }
        />
        <Route exact path="/reset-password" element={<ResetPassword />} />
        <Route exact path="/forgot-password" element={<ForgotPassword />} />
        <Route
          exact
          path="/privacy-policy"
          element={
            <RestrictPage>
              <PrivacyPolicy />
            </RestrictPage>
          }
        />
        <Route
          exact
          path="/terms-and-conditions"
          element={
            <RestrictPage>
              <TermsAndConditions />
            </RestrictPage>
          }
        />
      </Routes>
    </>
  );
}

export default App;

function RestrictPage({ children }) {
  const { isLoggedIn } = useSelector((state) => state.auth);
  return isLoggedIn ? children : <Navigate to="/login" replace />;
}
