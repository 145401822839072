import React from "react";

const Documents = ({ employeeDetails }) => {
  const handlePassportClick = () => {
    window.open(employeeDetails.passportFilePath, "_blank");
  };

  const handleLicenseClick = () => {
    window.open(employeeDetails.securityLicenseFilePath, "_blank");
  };

  return (
    <div>
      <div>
        {employeeDetails.passportFilePath && (
          <button onClick={handlePassportClick}>Passport Document</button>
        )}
      </div>
      <div>
        {employeeDetails.securityLicenseFilePath && (
          <button onClick={handleLicenseClick}>License Document</button>
        )}
      </div>
    </div>
  );
};

export default Documents;
