import React, { useEffect, useState } from "react";
import Navbar1 from "../../components/Navbar/Navbar1";
import EmployeeDetailSidebar from "../../components/Sidebar/EmployeeDetailSidebar";
import { useNavigate, useParams } from "react-router-dom";
import AdminApi from "../../api/AdminApi";
import { Divider } from "../../components/Common/Divider";
import { ArrowBack } from "@mui/icons-material";
import { Avatar } from "@mui/material";

import Trial3 from "../../../src/img/trial_3.jpg";
import DocumentUploadPopup from "../../components/Employee/DocumentUploadPopup";
import { SuccessMsgPopup } from "../../components/Employee/SuccessMsgPopup";
import EmployeeDetailsSidebarData from "./EmployeeDetailsSidebarData";
import Documents from "./Documents";

const EmployeeDetails = () => {
  const [loader, setLoader] = useState(false);
  const [isSelectMenu, setIsSelectMenu] = useState("Profile Overview");

  // set popup
  const [isError, setIsError] = useState();
  const [isOpen, setIsOpen] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const handleOnCancelClick = () => {
    setIsOpen(false);
  };
  const handleOnIsSuccessClick = () => {
    setIsSuccess(false);
    setIsOpen(false);
    setIsError("");
  };
  const handleOnContinueClick = async (empUpload) => {
    if (!empUpload.upload) {
      setIsError("Please Upload Document");
    } else {
      const formData = new FormData();
      formData.append("file", empUpload.upload);
      formData.append("employeeId", id);
      try {
        const uploadEmployeeDocResponse = await AdminApi.uploadEmployeeDocument(
          formData
        );
        setLoader(true);
        if (uploadEmployeeDocResponse.status === 200) {
          setLoader(false);
          setIsSuccess(true);
        }
      } catch (error) {
        setIsError(error.message);
      }
    }
  };

  const [employeeDetails, setEmployeeDetails] = useState({});
  const navigate = useNavigate();
  const { id } = useParams();
  useEffect(() => {
    fetchEmployeeDeatils();
  }, []);
  async function fetchEmployeeDeatils() {
    const formData = new FormData();
    formData.append("employeeId", id);
    try {
      const response = await AdminApi.fetchEmployeeDeatils(formData);
      if (response.status) {
        setEmployeeDetails(response.data.response);
      }
    } catch (error) {
      navigate("/employees");
    }
  }

  return (
    <>
      <Navbar1 />
      <div className="cstm-contant-main">
        <div className="cstm-contant-ed">
          <EmployeeDetailSidebar
            isSelectMenu={isSelectMenu}
            setIsSelectMenu={setIsSelectMenu}
          />

          <div className="cstm-contant-ed-right">
            {isSelectMenu === "Profile Overview" ? (
              <>
                <div className="cstm-prevnext">
                  <div className="cstm-pn-title">Previous/Next Employee </div>
                  <div className="pg-ar">
                    <button className="pg-ar-left">
                      <span className="material-icons">chevron_left</span>
                    </button>
                    <button className="pg-ar-right">
                      <span className="material-icons">chevron_right</span>
                    </button>
                  </div>
                </div>

                <div className="cstm-pfo-main">
                  <div className="cstm-pfo-main-left">Profile Overview</div>
                  <div className="cstm-pfo-main-right">
                    <div className="cstm-pfo-title">
                      Employee added on 31st July 2021
                    </div>
                    <div className="cstm-pfo-btn">
                      <button
                        className="btn btn--outline btn--medium"
                        onClick={() => setIsOpen(!isOpen)}
                      >
                        Mark Inactive
                      </button>
                    </div>
                  </div>
                </div>
                <Divider />
                <EmployeeDetailsSidebarData employeeDetails={employeeDetails} />
              </>
            ) : (
              <>
                <div className="cstm-prevnext"></div>
                <div className="cstm-pfo-main">
                  <div className="cstm-pfo-main-left">{isSelectMenu}</div>
                  <div className="cstm-pfo-main-right"></div>
                </div>
                <Divider />
                <Documents employeeDetails={employeeDetails} />
              </>
            )}
          </div>
        </div>
        {isOpen && (
          <DocumentUploadPopup
            onCancelClick={() => handleOnCancelClick()}
            onDataSubmit={handleOnContinueClick}
            isError={isError}
            loader={loader}
          />
        )}
        {isSuccess && (
          <SuccessMsgPopup onIsSuccessClick={() => handleOnIsSuccessClick()} />
        )}
      </div>
    </>
  );
};

export default EmployeeDetails;
